import React, { useState, useEffect } from 'react';
import * as S from './MultiDogFloatingdown.styles';

const MultiDogFloatingdown = props => {
  const { addDelayTime, delayTime, showTransition, transitionSpeed } = props;

  const [load, setLoad] = useState(!addDelayTime);
  const [visible, setVisible] = useState(true);
  const [transition, setTransition] = useState('');
  const [showFirst, setShowFirst] = useState(true);
  const [showSecond, setShowSecond] = useState(false);
  const [showThird, setShowThird] = useState(false);

  const triggerClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (!load && addDelayTime) {
      setTimeout(() => {
        setLoad(true);
        setVisible(true);

        setTimeout(() => {
          setTransition(showTransition ?? '');
        }, ((transitionSpeed ?? 1) + 1) * 1000);
      }, delayTime * 1000);
    }
  }, [
    addDelayTime,
    delayTime,
    load,
    setLoad,
    setVisible,
    showTransition,
    setTransition,
    transitionSpeed
  ]);

  const CloseButton = () => {
    return <S.CloseButton onClick={triggerClose}></S.CloseButton>;
  };

  const handleSecondCard = () => {
    setShowSecond(true);
    setShowFirst(false);
  };

  const handleThirdCard = () => {
    setShowThird(true);
    setShowSecond(false);
    setShowFirst(false);
    setTimeout(() => {
      setVisible(false);
    }, 5000);
  };

  return load ? (
    <S.Container>
      {showFirst && (
        <S.CardWrapper
          className={visible ? transition : 'slide-down'}
          start={showTransition}
          speed={transitionSpeed}
        >
          <img src="/images/Dog_absolute.png" alt="loading" />
          <S.FirstCard>
            <S.CardHeader>
              <span>Bark, bark!</span>
              <CloseButton />
            </S.CardHeader>
            <S.CardBody>
              <p>Do you have more than one dog?</p>
            </S.CardBody>
            <button className="yes_button" onClick={handleSecondCard}>
              Yes
            </button>
            <button className="no_button" onClick={handleThirdCard}>
              No
            </button>
          </S.FirstCard>
        </S.CardWrapper>
      )}
      {showSecond && (
        <S.CardWrapper
          className={visible ? transition : 'slide-down'}
          start={showTransition}
          speed={transitionSpeed}
        >
          <S.SecondCard>
            <S.CardHeader>
              <span>Ok, understood... </span>
              <CloseButton />
            </S.CardHeader>
            <S.CardBody>
              <p>How many dogs do you have?</p>
            </S.CardBody>
            <button className="dogs_2" onClick={handleThirdCard}>
              2 dogs
            </button>
            <button className="dogs_3" onClick={handleThirdCard}>
              3 dogs
            </button>
            <button className="dogs_4" onClick={handleThirdCard}>
              4 dogs
            </button>
            <button className="over_4_dogs" onClick={handleThirdCard}>
              Over 4 dogs
            </button>
          </S.SecondCard>
        </S.CardWrapper>
      )}
      {showThird && (
        <S.CardWrapper
          className={visible ? transition : 'slide-down'}
          start={showTransition}
          speed={transitionSpeed}
        >
          <S.LastCard>
            <S.CardHeader>
              <span>We're researching...</span>
              <CloseButton />
            </S.CardHeader>
            <p>
              Thanks for sharing. We’ll use this to develop even better products
              for you in the future😊🙏{' '}
            </p>
          </S.LastCard>
        </S.CardWrapper>
      )}
    </S.Container>
  ) : (
    <></>
  );
};

export default MultiDogFloatingdown;
