import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  right: 80px;
  max-width: 200px;
  z-index: 8000;
  font-size: 19px;

  line-height: 1.6;
  &.hidden {
    display: none;
  }

  @media (max-width: 500px) {
    font-size: 13px;
    max-width: 220px;
    right: 0px;
    bottom: 300px;
  }
`;

export const CloseButton = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  font-size: 22px;
  bottom: 7px;
  &:after {
    content: ' \\2715';
  }
  &:hover {
    cursor: pointer;
  }
`;

export const CardWrapper = styled.div`
  position: relative;

  & img {
    width: 60px;
    height: 80px;
    z-index: 999;
    position: relative;
    top: 40px;
    left: 110px;
  }

  ${({ start }) => {
    switch (start) {
      case 'slide-up':
        return `
          transition: 2s cubic-bezier(0, 1, 0.5, 1);
          transform: translateY(100%);
        `;
      default:
        return ``;
    }
  }}

  &.slide-up {
    transform: translateY(0);
  }

  @media (max-width: 500px) {
    ${({ start }) => {
      switch (start) {
        case 'slide-up':
          return `
          transition: 2s cubic-bezier(0, 1, 0.5, 1);
          transform: translateX(100%);
        `;
        default:
          return ``;
      }
    }}

    &.slide-up {
      transform: translateX(0);
    }
  }
`;

export const FirstCard = styled.div`
  position: relative;
  border: 1px solid #ccd2e3;
  text-align: center;
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;
  padding-bottom: 32px;

  & button {
    font-family: Neuzeit Grotesk;
    background-color: #ffffff;
    border: 1px solid #ccd2e3;
    border-radius: 4px;
    color: #001c72;
    padding: 4px 27px;
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
    text-align: center;

    &:hover {
      background-color: #ccd2e3;
    }
  }

  & button:first-of-type {
    margin-right: 12px;
  }

  @media (max-width: 500px) {
    border-radius: 8px 0px 0 8px;
  }
`;

export const SecondCard = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  background-color: #ffffff;
  border: 1px solid #ccd2e3;
  text-align: center;
  border-radius: 8px 8px 0 0;
  color: #001c72;
  font-family: Neuzeit Grotesk;
  opacity: 1;
  transition: opacity 1s ease-in-out;

  & p {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
  }

  & button {
    margin-top: 8px;
    margin-bottom: 0px;
    margin-inline: 16px;
    font-family: Neuzeit Grotesk;
    background-color: #ffffff;
    border: 1px solid #ccd2e3;
    border-radius: 4px;
    color: #001c72;
    padding: 4px 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
    text-align: left;
    & :hover {
      background-color: #ccd2e3;
    }
  }

  @media (max-width: 500px) {
    border-radius: 8px 0px 0 8px;
  }
`;

export const LastCard = styled.div`
  max-width: 200px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #ffffff 0%, #f1edf5 100%);
  border: 1px solid #ccd2e3;
  text-align: center;
  border-radius: 8px 8px 0 0;
  color: #001c72;
  font-family: Neuzeit Grotesk;

  & p {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    padding-inline: 16px;
  }

  & button {
    margin-bottom: 20px;
    font-family: Neuzeit Grotesk;
    background-color: #ffffff;
    border: 1px solid #ccd2e3;
    border-radius: 4px;
    color: #001c72;
    padding: 4px 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
    text-align: left;
    & :hover {
      background-color: #ccd2e3;
    }
  }

  @media (max-width: 500px) {
    border-radius: 8px 0px 0 8px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 5px 4px 16px;
  height: 32px;
  background: #f8f8f8;
  border-bottom: 1px solid #ccd2e3;
  border-radius: 8px 8px 0px 0px;

  & span {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
  }
`;

export const CardBody = styled.div`
  margin-bottom: 8px;
  color: #001c72;
  font-family: Neuzeit Grotesk;
  padding: 8px 16px 0px;
  & p {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
  }
`;
